import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {Avatar, Collapse, List, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import React, {createContext, ForwardedRef, forwardRef, Fragment, useContext, useMemo, useState} from "react";
import {Location, LocationAncestry, LocationTree} from "../../api";
import {useLocalization} from "../../i18n";
import {LocationIcon} from "./LocationIcon";

export interface LocationTreeViewProps {
	locations: LocationTree[];
	selected?: LocationTree | Location | LocationAncestry;
	onSelect: (location: LocationTree) => void;
	loading?: boolean;
}

export const LocationTreeView = forwardRef((props: LocationTreeViewProps, ref: ForwardedRef<HTMLUListElement>) => {
	const {locations, selected, onSelect, loading} = props;
	const [collapsed, setCollapsed] = useState<number[]>([]);

	const context = useMemo(() => ({
		collapsed,
		selected,
		onSelect: (location: LocationTree) => {
			onSelect(location);
			if (location.children.length > 0) {
				if (!collapsed.includes(location.id)) {
					setCollapsed([...collapsed, location.id]);
				}
				else {
					setCollapsed(prev => prev.filter(collapsedId => collapsedId != location.id));
				}
			}
		},
	}), [collapsed, selected]);

	return (
		<LocationTreeViewContext.Provider value={context}>
			<List dense sx={{opacity: loading ? 0.5 : 1}} ref={ref}>
				{locations?.map(location => <LocationTreeItem key={location.id} location={location} />)}
			</List>
		</LocationTreeViewContext.Provider>
	);
});

interface LocationTreeViewContextContent {
	collapsed: number[];
	selected?: LocationTree | Location;
	onSelect: (location: LocationTree) => any;
}

const LocationTreeViewContext = createContext<LocationTreeViewContextContent>({
	collapsed: [],
	selected: undefined,
	onSelect: () => void 0,
});

interface LocationTreeItemProps {
	location: LocationTree;
}

function LocationTreeItem({location}: LocationTreeItemProps) {
	const {t} = useLocalization();
	const {collapsed, selected, onSelect} = useContext(LocationTreeViewContext);

	const hasChildren = location.children.length > 0;
	const isCollapsed = collapsed.includes(location.id);
	return (
		<Fragment>
			<ListItemButton
				onClick={() => onSelect(location)}
				selected={selected == location}
				sx={{opacity: location.enabled ? 1 : 0.5}}
			>
				<ListItemAvatar>
					<Avatar>
						<LocationIcon type={location.type} />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={location.name}
					secondary={location.type && t(`locations.types.${location.type}`)}
				/>
				{hasChildren && (isCollapsed ? <ExpandMore /> : <ExpandLess />)}
			</ListItemButton>
			{hasChildren && (
				<Collapse in={!isCollapsed}>
					<List dense sx={{pl: 3}}>
						{location.children.map(location => <LocationTreeItem key={location.id} location={location} />)}
					</List>
				</Collapse>
			)}
		</Fragment>
	);
}
