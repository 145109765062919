import {CardContent, CardHeader} from "@mui/material";
import {EditForm, TextField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useLocalization} from "../../i18n";

interface CompartmentLockFormProps {
	loading: boolean;
	lockId: string | undefined;
	onSave: (lockId: string | undefined) => Promise<any>;
}

export function CompartmentLockForm(props: CompartmentLockFormProps) {
	const {loading, lockId, onSave} = props;
	const {t} = useLocalization();

	const [newLockId, setNewLockId] = useState(lockId ?? "");

	useEffect(() => {
		setNewLockId(lockId ?? "");
	}, [lockId]);

	async function handleSave() {
		await onSave(newLockId || undefined);
	}

	return (
		<EditForm loading={loading} onSave={handleSave} labels={t}>
			<CardHeader title={t("compartments.lock.title")} subheader={t("compartments.lock.editHelperText")} />
			<CardContent>
				<TextField
					label={t("compartments.lock.title")}
					value={newLockId}
					onChange={setNewLockId}
					fullWidth
				/>
			</CardContent>
		</EditForm>
	);
}
