import {CardContent, CardHeader, Stack} from "@mui/material";
import {Checkbox, EditForm, TextField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {Compartment, CompartmentMutation} from "../../api";
import {useLocalization} from "../../i18n";

interface CompartmentEditFormProps {
	loading: boolean;
	compartment?: Compartment;
	onSave: (mutation: CompartmentMutation) => Promise<any>;
}

export function CompartmentEditForm(props: CompartmentEditFormProps) {
	const {loading, compartment, onSave} = props;
	const {t} = useLocalization();

	const [number, setNumber] = useState("");
	const [size, setSize] = useState("");
	const [enabled, setEnabled] = useState<boolean>(true);

	useEffect(() => {
		if (compartment) {
			setNumber(compartment.number);
			setSize(compartment.size ?? "");
			setEnabled(compartment.enabled);
		}
	}, [compartment]);

	async function handleSave() {
		await onSave({
			number,
			size,
			enabled,
		});
	}

	return (
		<EditForm loading={loading} onSave={handleSave} labels={t}>
			<CardHeader title={t("compartments.settings")} />
			<CardContent>
				<Stack spacing={2}>
					<TextField
						label={t("compartments.number")}
						value={number}
						onChange={setNumber}
						fullWidth
						required
					/>
					<TextField
						label={t("compartments.size")}
						value={size}
						onChange={setSize}
						fullWidth
					/>
					<Checkbox
						label={t("compartments.enabled")}
						value={enabled}
						onChange={setEnabled}
					/>
				</Stack>
			</CardContent>
		</EditForm>
	);
}
