import {createLocalizationContext} from "@variocube/app-ui";

export const {StorageLocalizationProvider, useLocalization} = createLocalizationContext<typeof import("./en.json")>({
	load: lang => import(`./${lang}.json`),
	missing: location.hostname == "localhost" ? key => `??${key}??` : "fallback",
	fallback: "en",
});

export const SUPPORTED_LANGUAGES = [
	{language: "de", displayName: "Deutsch"},
	{language: "en", displayName: "English"},
	{language: "fr", displayName: "Français"},
] as const;

export type TFunc = ReturnType<typeof useLocalization>["t"];
export type Keys = Parameters<TFunc>[0];
