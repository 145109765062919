import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import {
	BoxNumber,
	Checkbox,
	DataTable,
	DataTableCellProps,
	ErrorAlert,
	useDataTableStorage,
	useFlag,
	useSpringPage,
	useSpringPageable,
} from "@variocube/app-ui";
import React, {useEffect, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {Compartment, CompartmentQuery, LocationTree, useCompartmentApi} from "../../api";
import {EmptyList} from "../../controls";
import {useLocalization} from "../../i18n";
import {LocationChip} from "../location/LocationChip";
import {LocationSelect} from "../location/LocationSelect";

export interface CompartmentSearchProps {
	onCompartmentSelected: (compartment: Compartment) => void;
	onClose: () => void;
	open: boolean;
}

export function CompartmentSearch(props: CompartmentSearchProps) {
	const {t} = useLocalization();
	const {queryCompartments} = useCompartmentApi();

	const [open, setOpen, clearOpen] = useFlag(props.open);
	useEffect(() => {
		props.open ? setOpen() : clearOpen();
	}, [props.open]);

	const select = () => {
		if (selectedCompartment) {
			props.onCompartmentSelected(selectedCompartment);
		}
	};

	const onClose = () => {
		clearOpen();
		props.onClose();
	};

	const [location, setLocation] = useState<LocationTree | null>(null);
	const [available, setAvailable] = useState<boolean>(true);
	const [selectedSizes, setSelectedSizes] = useState<string[]>([]);
	const [compartmentSize, setCompartmentSize] = useState<string>();
	const availableSizes = ["S", "M", "L", "XL"]; // TODO - how to get all available sizes ?

	const query: CompartmentQuery = useMemo(() => {
		return {
			locationId: location?.id,
			status: available ? "AVAILABLE" : "BOOKED",
			compartmentSize,
		};
	}, [location, available, compartmentSize]);

	const selectSize = (size: string) => {
		setSelectedSizes([size]);
		setCompartmentSize(size);
	};

	const removeSize = (size: string) => {
		setSelectedSizes(selectedSizes.filter(s => s !== size));
		setCompartmentSize(undefined);
	};

	const columns = useMemo(() => [
		{
			label: t("compartments.number"),
			field: "number",
			sortable: true,
			default: true,
			component: ({row}: DataTableCellProps<Compartment>) => (
				<Button onClick={() => setSelectedCompartment(row)}>
					<BoxNumber number={row.number} />
				</Button>
			),
		},
		{
			label: t("compartments.size"),
			field: "size",
			sortable: true,
			default: true,
		},
		{
			label: t("compartments.location.title"),
			field: "location.name",
			sortable: true,
			default: true,
			component: ({row}: DataTableCellProps<Compartment>) => <LocationChip location={row.location} />,
		},
		{
			label: t("compartments.lock.status"),
			field: "lockingState",
			sortable: false,
			default: true,
		},
	], [t]);

	const {onPageChange, onSort, ...storage} = useDataTableStorage("LocationList.paging");
	const pageable = useSpringPageable(storage);
	const {loading, error, result} = useAsync(() => queryCompartments(query), [
		pageable,
		compartmentSize,
		location,
		available,
	]);
	const {rows, page} = useSpringPage(result);

	const [selectedCompartment, setSelectedCompartment] = useState<Compartment>();

	useEffect(() => {
		if (selectedCompartment && props.onCompartmentSelected) {
			props.onCompartmentSelected(selectedCompartment);
		}
	}, [selectedCompartment]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
			<DialogTitle>{t("compartments.searchdialog.title")}</DialogTitle>
			<DialogContent>
				<Card>
					<CardContent>
						<LocationSelect
							value={location}
							onChange={setLocation}
						/>
						<Box my={2} />
						<Box>
							{t("compartments.size")}:
							{selectedSizes && availableSizes.map(size => (
								<Chip
									key={size}
									label={size}
									variant={selectedSizes.indexOf(size) >= 0 ? "filled" : "outlined"}
									onClick={() => selectSize(size)}
									onDelete={() => removeSize(size)}
									style={{margin: 4}}
								/>
							))}
						</Box>
						<Box my={2} />

						<Checkbox
							label={t("compartments.available")}
							checked={available}
							onChange={setAvailable}
						/>
					</CardContent>
				</Card>

				<DataTable
					columns={columns}
					rows={rows}
					page={page}
					loading={loading}
					error={error}
					onSort={onSort}
					onPageChange={onPageChange}
					empty={<EmptyList />}
					{...storage}
				/>
			</DialogContent>
			{error && (
				<DialogContent>
					<ErrorAlert error={error} />
				</DialogContent>
			)}
			<DialogActions>
				<Button onClick={onClose}>{t("cancel")}</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={select}
					startIcon={loading && <CircularProgress />}
				>
					{t("select")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
