import {Alert, AlertTitle} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, ConfirmButton} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {PasswordReset, UserMutation, useTenantUserApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useTenantPermission} from "../../tenant";
import {useParam} from "../../utils/useParam";
import {View, ViewHeader} from "../View";
import {UserEditForm} from "./UserEditForm";
import {UserPasswordForm} from "./UserPasswordForm";

export function UserEdit() {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const username = useParam("username");

	const [user, setUser] = useState<UserMutation>();

	const {getUser, updateUser, deleteUser, resetPassword} = useTenantUserApi();
	const {loading, error, result} = useAsync(getUser, [username]);

	const canDelete = useTenantPermission("TENANT_USER_DELETE");

	useEffect(() => {
		if (result) {
			setUser(result.user);
		}
	}, [result]);

	async function handleSave(mutation: UserMutation) {
		setUser(await updateUser(username, mutation));
	}

	async function handleDelete() {
		await deleteUser(username);
		navigate(-2); // -1 would be the user view, so we do -2
	}

	async function handlePasswordReset(passwordReset: PasswordReset) {
		await resetPassword(username, passwordReset);
	}

	return (
		<View error={error}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={"../.."} relative="path">{t("users.title")}</BreadcrumbLink>
				<BreadcrumbLink component={Link} to={".."} relative="path">{user?.displayName}</BreadcrumbLink>
				<BreadcrumbItem>{t("edit")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("users.edit.title", user)} />
			<UserEditForm loading={loading} user={user} onSave={handleSave} />
			<UserPasswordForm onSave={handlePasswordReset} />
			{canDelete && (
				<Alert
					severity="error"
					action={
						<ConfirmButton
							cancel={t("cancel")}
							title={t("users.delete.title")}
							onConfirm={handleDelete}
							color="error"
						>
							{t("users.delete.confirm")}
						</ConfirmButton>
					}
				>
					<AlertTitle>
						{t("users.delete.title")}
					</AlertTitle>
					{t("users.delete.message")}
				</Alert>
			)}
		</View>
	);
}
