import {CardContent, CardHeader} from "@mui/material";
import {EditForm} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {LocationAncestry, LocationTree} from "../../api";
import {useLocalization} from "../../i18n";
import {LocationSelect} from "../location/LocationSelect";

interface CompartmentLocationFormProps {
	loading: boolean;
	location: LocationAncestry | LocationTree | undefined;
	onSave: (location: LocationAncestry | LocationTree) => Promise<any>;
}

export function CompartmentLocationForm(props: CompartmentLocationFormProps) {
	const {loading, location, onSave} = props;
	const {t} = useLocalization();

	const [newLocation, setNewLocation] = useState<LocationAncestry | LocationTree | null>(location ?? null);

	useEffect(() => {
		setNewLocation(location ?? null);
	}, [location]);

	async function handleSave() {
		if (newLocation) {
			await onSave(newLocation);
		}
	}

	return (
		<EditForm loading={loading} onSave={handleSave} labels={t}>
			<CardHeader
				title={t("compartments.location.title")}
				subheader={t("compartments.location.editHelperText")}
			/>
			<CardContent>
				<LocationSelect required fullWidth value={newLocation} onChange={setNewLocation} />
			</CardContent>
		</EditForm>
	);
}
