import {Visibility, VisibilityOff} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {TextField, useFlag} from "@variocube/app-ui";
import React from "react";
import {useLocalization} from "../i18n";

interface PasswordFieldProps {
	value: string;
	onChange: (value: string) => any;
}

export function PasswordField({value, onChange}: PasswordFieldProps) {
	const {t} = useLocalization();
	const [passwordVisible, , , togglePasswordVisible] = useFlag(false);
	return (
		<TextField
			label={t("users.password.title")}
			value={value}
			onChange={onChange}
			type={passwordVisible ? "text" : "password"}
			InputProps={{
				endAdornment: (
					<IconButton onClick={togglePasswordVisible}>
						{passwordVisible ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				),
			}}
			fullWidth
		/>
	);
}
