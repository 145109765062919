import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {ClickAwayListener, IconButton, Popover, TextField} from "@mui/material";
import {ErrorAlert, useFlag} from "@variocube/app-ui";
import React, {Fragment, useState} from "react";
import {useAsync} from "react-async-hook";
import {Location, LocationAncestry, LocationTree, useLocationApi} from "../../api";
import {useLocalization} from "../../i18n";
import {LocationTreeView} from "./LocationTreeView";

export interface LocationSelectProps {
	value: LocationTree | Location | LocationAncestry | null;
	onChange: (location: LocationTree | null) => void;
	fullWidth?: boolean;
	required?: boolean;
}

export function LocationSelect({value, onChange, fullWidth, required}: LocationSelectProps) {
	const {t} = useLocalization();
	const {getLocationTrees} = useLocationApi();

	const {loading, error, result} = useAsync(getLocationTrees, []);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [open, setOpen, clearOpen, toggleOpen] = useFlag(false);

	function handleSelect(location: LocationTree) {
		onChange(location);
		clearOpen();
	}

	return (
		<Fragment>
			{error && <ErrorAlert error={error} />}
			<TextField
				label={t("locations.single")}
				onClick={setOpen}
				value={value?.name ?? ""}
				ref={setAnchorEl}
				fullWidth={fullWidth}
				InputProps={{
					endAdornment: (
						<IconButton onClick={toggleOpen}>
							{open ? <ExpandLess /> : <ExpandMore />}
						</IconButton>
					),
				}}
				required={required}
			/>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={clearOpen}
				anchorOrigin={{
					horizontal: "left",
					vertical: "bottom",
				}}
			>
				<ClickAwayListener onClickAway={clearOpen}>
					<LocationTreeView
						loading={loading}
						selected={value ?? undefined}
						locations={result ?? []}
						onSelect={handleSelect}
					/>
				</ClickAwayListener>
			</Popover>
		</Fragment>
	);
}
