import {CardContent, CardHeader} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {EditForm, TextField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {UserMutation} from "../../api";
import {useLocalization} from "../../i18n";

interface UserEditFormProps {
	loading: boolean;
	user?: UserMutation;
	onSave: (mutation: UserMutation) => Promise<any>;
}

export function UserEditForm(props: UserEditFormProps) {
	const {loading, user, onSave} = props;
	const {t} = useLocalization();

	const [givenName, setGivenName] = useState("");
	const [familyName, setFamilyName] = useState("");
	const [displayName, setDisplayName] = useState("");
	const [email, setEmail] = useState("");

	useEffect(() => {
		if (user) {
			setGivenName(user.givenName ?? "");
			setFamilyName(user.familyName ?? "");
			setDisplayName(user.displayName ?? "");
			setEmail(user.email ?? "");
		}
	}, [user]);

	async function handleSave() {
		await onSave({
			givenName,
			familyName,
			displayName,
			email,
		});
	}

	return (
		<EditForm loading={loading} onSave={handleSave} labels={t}>
			<CardHeader title={t("users.name")} />
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							label={t("users.givenName")}
							value={givenName}
							onChange={setGivenName}
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							label={t("users.familyName")}
							value={familyName}
							onChange={setFamilyName}
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12} md={8}>
						<TextField
							label={t("users.displayName")}
							value={displayName}
							onChange={setDisplayName}
							fullWidth
							required
						/>
					</Grid>
				</Grid>
			</CardContent>
			<CardHeader title={t("users.email")} />
			<CardContent>
				<Grid container>
					<Grid item xs={12} md={8}>
						<TextField
							label={t("users.email")}
							value={email}
							onChange={setEmail}
							type="email"
							fullWidth
							required
						/>
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	);
}
