import {Card, CardContent, CardHeader, FormHelperText} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {Checkbox, EditForm} from "@variocube/app-ui";
import React, {useState} from "react";
import {PasswordReset} from "../../api";
import {PasswordField} from "../../controls";
import {useLocalization} from "../../i18n";

interface UserPasswordFormProps {
	onSave: (passwordReset: PasswordReset) => Promise<any>;
}

export function UserPasswordForm({onSave}: UserPasswordFormProps) {
	const {t} = useLocalization();
	const [forcePasswordChange, setForcePasswordChange] = useState(true);
	const [password, setPassword] = useState("");

	async function handleSave() {
		await onSave({
			password,
			forcePasswordChange,
		});
	}

	return (
		<Card>
			<EditForm loading={false} onSave={handleSave} labels={t}>
				<CardHeader title={t("users.resetPassword.title")} />
				<CardContent>
					<Grid container>
						<Grid item xs={12} md={8}>
							<PasswordField value={password} onChange={setPassword} />
							<FormHelperText>{t("users.password.helperText")}</FormHelperText>
						</Grid>
					</Grid>
				</CardContent>
				<CardContent>
					<Checkbox
						label={t("users.forcePasswordChange.title")}
						checked={forcePasswordChange}
						onChange={setForcePasswordChange}
						disabled={!password}
					/>
					<FormHelperText>{t("users.forcePasswordChange.helperText")}</FormHelperText>
				</CardContent>
			</EditForm>
		</Card>
	);
}
